






























import {GradeStatusCounts} from '@/assignments/types/GradeStatusCounts';
import {computed, defineComponent, PropType} from '@vue/composition-api';
import {useTranslate} from '@/locales/composables/i18n';
import {inject} from '@/container';
import {ThemeService} from '@/theme/services';
import {GradeStatus} from '@/assignments/types/GradeStatus';

type Bar = {number: number; text: string; color: string; isAdjusted: boolean; status: GradeStatus};
export default defineComponent({
  name: 'AssignmentProgressBar',
  props: {
    assignmentGradesStatus: {
      type: Object as PropType<GradeStatusCounts>,
      required: true,
    },
    numTasks: {
      type: Number as PropType<number>,
      required: true,
    },
    height: {
      type: Number as PropType<number>,
      required: false,
      default: 20,
    },
    incompleteColor: {
      type: String as PropType<string>,
      required: false,
      default: 'white',
    },
    showProgress: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const $t = useTranslate();
    const themeService = inject(ThemeService);

    const bars = computed(() => {
      const bars: Bar[] = [];
      for (const [key, numStatuses] of Object.entries(props.assignmentGradesStatus)) {
        const color = themeService.getTheme()[key];
        const statusType = $t(`responseStatusMessages.${key}`);
        const status = key as GradeStatus;

        bars.push({
          number: numStatuses.unadjusted,
          color,
          text: `${numStatuses.unadjusted.toString()} ${statusType}`,
          isAdjusted: false,
          status,
        });

        if (numStatuses.adjusted) {
          bars.push({
            number: numStatuses.adjusted,
            color,
            text: `${numStatuses.adjusted.toString()} ${statusType} ${$t(
              `responseStatusMessages.adjusted`
            )}`,
            isAdjusted: true,
            status,
          });
        }
      }

      return bars;
    });

    const calcTotal = computed(() => {
      const result = bars.value.reduce((acc, bar) => acc + bar.number, 0);
      return props.numTasks < result ? result : props.numTasks;
    });

    const barWidths = computed(() =>
      bars.value.map((bar) => Math.fround((bar.number / calcTotal.value) * 100))
    );

    const barHeight = computed(() => {
      if (typeof props.height === 'number') {
        return `${props.height}px`;
      }
      return props.height;
    });

    return {
      bars,
      calcTotal,
      barWidths,
      barHeight,
    };
  },
});
