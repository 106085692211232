




























































































import {computed, defineComponent, PropType, ref, Ref, watch} from '@vue/composition-api';
import {useAsyncState, useVModel} from '@vueuse/core';
import {useRoute} from '@/router/composables';
import {Course, CourseSection} from '@/courses/models';
import {getAssignmentParticipants} from '@/assignments/api/assignments';

type SimpleUser = {id: number; firstName: string; lastName: string; sectionIds: string};
type Section = {id: number; name: string | undefined; participants: SimpleUser[]};
export default defineComponent({
  name: 'UserSelector',
  props: {
    assignmentId: {
      type: Number as PropType<number>,
      required: true,
    },
    user: {
      type: Object as PropType<SimpleUser>,
      required: true,
    },
    includeNavigation: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  model: {
    prop: 'user',
    event: 'update:user',
  },

  setup(props, {emit}) {
    const route = useRoute();
    const internalValue = useVModel(props, 'user', emit);
    let participants = ref<SimpleUser[]>([]);
    const selectedUser = ref(null);
    const allSection = {
      id: 0,
      name: 'All my Sections',
      participants: [] as SimpleUser[],
    };
    const selectedSection = ref<Section>(allSection);
    const courseId = route.params.courseId;
    const sections = ref<Section[]>([]);
    const filteredSections = ref<Section[]>([]);

    if (courseId) {
      const courseSections = Course.find(+courseId)?.sectionIds;
      if (courseSections) {
        sections.value = courseSections.map((sectionId) => {
          const section = CourseSection.find(sectionId);
          return {id: sectionId, name: section?.name, participants: [] as SimpleUser[]};
        });
        sections.value.push(allSection);
        sections.value.sort((a, b) => a.id - b.id);
      }
    }

    const {state: items, execute} = useAsyncState<any>(
      async () => {
        const data = await getAssignmentParticipants(props.assignmentId, null);
        return data.data;
      },
      [],
      {resetOnExecute: true}
    );

    watch(items, () => {
      participants.value = items.value;
      participants.value.forEach((participant) => {
        const section = sections.value.find((s) => s.id === +participant.sectionIds);
        if (section) {
          section.participants.push(participant);
        }
      });
      sections.value[0].participants.push(...participants.value);
      filteredSections.value = sections.value.filter(
        (sections) => sections.participants.length > 0
      );
      if (filteredSections.value.length > 2) {
        // Remove the first element, sort the rest, and then re-insert the first element
        let sortedArrExceptFirst = filteredSections.value.slice(1);

        sortedArrExceptFirst?.sort((a, b) => {
          if (a.name && b.name) {
            return a.name?.localeCompare(b.name);
          }
          return 0;
        });
        filteredSections.value = [filteredSections.value[0], ...sortedArrExceptFirst];
      }
    });

    watch(() => props.assignmentId, execute);

    async function filterPerSection() {
      const data = await getAssignmentParticipants(props.assignmentId, selectedSection.value.id);
      participants.value = data.data;

      if (participants.value.findIndex((user) => user.id === internalValue.value.id) === -1) {
        internalValue.value = participants.value[0];
      }
    }

    const selectedIndex = computed(() => {
      return participants.value.findIndex((user) => user.id === internalValue.value.id) ?? 0;
    });

    const hasPrevious = computed(() => {
      return selectedIndex.value - 1 >= 0;
    });

    const hasNext = computed(() => {
      return selectedIndex.value + 1 <= participants.value.length - 1;
    });

    const previousUser: Ref<SimpleUser | null> = computed(() => {
      if (hasPrevious.value) {
        return participants.value[selectedIndex.value - 1];
      }

      return null;
    });

    const nextUser: Ref<SimpleUser | null> = computed(() => {
      if (hasNext) {
        return participants.value[selectedIndex.value + 1];
      }

      return null;
    });

    function next() {
      if (selectedIndex.value + 1 < participants.value.length) {
        internalValue.value = participants.value[selectedIndex.value + 1];
      }
    }

    function previous() {
      if (selectedIndex.value - 1 >= 0) {
        internalValue.value = participants.value[selectedIndex.value - 1];
      }
    }

    return {
      items,
      internalValue,
      selectedIndex,
      hasPrevious,
      hasNext,
      previousUser,
      nextUser,
      next,
      previous,
      selectedSection,
      participants,
      selectedUser,
      filterPerSection,
      filteredSections,
    };
  },
});
