<template>
  <v-container
    v-if="isReady"
    id="quiz"
    fluid
    tag="section"
    class="px-5 pl-md-0 pr-md-8 pr-lg-12 pt-4"
    justify="center"
  >
    <v-row no-gutters>
      <v-col cols="12" lg="9" md="9">
        <div class="px-md-8 px-lg-16">
          <div class="d-flex align-end justify-space-between mb-3">
            <h3 class="display-2 font-weight-medium" :data-test-page-name="assignment.name">
              {{ assignment.name }}
            </h3>
            <span
              class="s-badge lg d-flex align-center mb-1"
              :class="{
                white: numCompletedTasks < numTasks,
                green: numCompletedTasks >= numTasks,
              }"
            >
              <v-icon v-if="numCompletedTasks >= numTasks" color="#449c47" class="pr-1 my-n1">
                mdi-check
              </v-icon>
              <span class="font-weight-bold">{{ numCompletedTasks }}</span>
              <span class="opacity-70">of</span>
              <span class="font-weight-bold">{{ numTasks }}</span>
              <span class="opacity-70">Tasks Submitted</span>
            </span>
          </div>
          <try-it-area
            ref="tryItArea"
            :task="currentTask"
            :task-assignment="currentTaskAssignment"
            :assignment="assignment"
            :responses="currentResponses"
            :response="currentResponse"
            :task-state="currentTaskState"
            :feedback-by-part="currentFeedback"
            :grade="currentGrade"
            :effective-grade="currentEffectiveGrade"
            :task-index="taskIndex"
            :response-index="currentResponseIndex"
            :user="user"
            :disable-submit="isMarking"
            :is-marking="isMarking"
            :loading="isStateLoading || isResponsesLoading"
            :allow-regrade="allowRegrade"
            :assignment-extension="latestExtension"
            @change-selected-response="changeSelectedResponse"
            @reload-task="reloadTask"
            @attachments-updated="fetchTaskResponses"
            @response-submitted="delayedFetchAssignmentGrades"
          />
          <div v-if="isMarking && currentTaskState">
            <v-card width="100%">
              <v-card-title> Task State </v-card-title>
              <v-card-text>
                <v-switch v-model="showRandomVariables" label="Show Random Variables" />
                <raw-state-dump :task-state="currentTaskState" />
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div v-if="!hasNoTasks" justify="center" class="text-center py-4">
          <v-pagination
            :value="taskIndex"
            :length="numTasks"
            :total-visible="7"
            color="secondary"
            circle
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="gotoTask($event)"
            @next="next"
            @previous="previous"
          />
        </div>
        <div v-else class="px-lg-16 px-md-16 mt-n5">
          <v-card class="" width="100%">
            <v-card-text> No tasks </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col>
        <div v-if="isMarking" class="user-navigation">
          <div>
            <user-selector
              v-if="isMarking"
              :assignment-id="assignmentId"
              :user="user"
              :include-navigation="true"
              @update:user="changeSelectedUser($event)"
            />
          </div>
          <div>
            <v-btn
              color="secondary"
              rounded
              width="100%"
              :href="`/courses/${course.id}/assignments/${assignmentId}/grades`"
            >
              All Grades
            </v-btn>
          </div>
        </div>
        <course-like-assignment-info
          :total-assignment-marks="totalAssignmentMarks"
          :assignment-grade="isGradeLocked ? 'locked' : assignmentGrade"
          :assignment="assignment"
          :course-like-assignments="courseLikeAssignments"
          :course="course"
          :assignment-extension="latestExtension"
          :is-new-assignment="false"
          :is-marking="isMarking"
        />

        <template v-if="!hasNoTasks">
          <v-row
            class="display-2 font-weight-medium mt-6 text-capitalize"
            align="baseline"
            justify="space-between"
            no-gutters
          >
            <p style="font-size: 1.375rem; font-weight: 500; line-height: 1.2; margin: 0">
              {{ $t('quiz-navigation') }}
            </p>

            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-btn icon text v-bind="attrs" v-on="on">
                  <v-icon color="secondary"> mdi-information </v-icon>
                </v-btn>
              </template>
              <v-col
                v-for="(toolTipNavi, index) in toolTip_navi.length"
                :key="toolTipNavi"
                class="white--text"
                justify="start"
              >
                <v-row align="center">
                  <v-icon :color="toolTip_navi[index].color" class="pr-3">
                    mdi-checkbox-blank-circle
                  </v-icon>
                  {{ toolTip_navi[index].title }}
                </v-row>
              </v-col>
            </v-tooltip>
          </v-row>

          <v-row no-gutters>
            <p class="task-marks">
              <strong>Task {{ taskIndex }}</strong> is worth
              <strong
                >{{ currentTaskMarks }} mark{{ parseInt(currentTaskMarks) > 1 ? 's' : '' }}</strong
              >
            </p>
            <div class="task-navigation-list">
              <task-navigation-card
                v-for="(task, i) in filteredTasks"
                :key="task.id"
                :index="i + 1"
                :active="taskIndex === i + 1"
                :has-attachments="attemptAttachments(filteredResponses[i])"
                :response="selectResponseForNavCard(filteredResponses[i], filteredGrades[i])"
                :grade="filteredGrades[i]"
                data-test="task"
                :data-test-id="task.id"
                @click="gotoTask(i + 1)"
              />
            </div>
          </v-row>
        </template>
        <v-row v-if="$gate.isFaculty() && preview_assignment" justify="center" class="mt-4">
          <v-col cols="12" lg="6" md="6">
            <v-btn outlined rounded color="grey" width="100%" height="35px" to="">
              {{ $t('question-bank') }}
            </v-btn>
          </v-col>
          <v-col cols="12" lg="6" md="6">
            <v-btn rounded color="secondary" width="100%" height="35px" to="">
              {{ $t('post') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="submitQuiz" max-width="390">
      <v-card>
        <v-card-title class="headline">
          {{ $t('r-u-sure') }}
        </v-card-title>
        <v-card-text>{{ $t('no-change-quiz') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary darken-1" text @click="submitQuiz = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="secondary darken-1"
            class="font-weight-bold"
            text
            @click="
              submitQuiz = false;
              submit();
            "
          >
            {{ $t('submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue';
import Assignment from '@/assignments/models/Assignment';
import {Course} from '@/courses/models';
import Grade from '@/grades/models/Grade';
import TaskResponse from '@/tasks/models/TaskResponse';
import AssignmentExtension from '@/assignments/models/AssignmentExtension';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import {ASSIGNMENT_OVERVIEW, MARKING_TASKS} from '@/router/route-names';
import TaskState from '../../task-states/models/TaskState';
import IssuedTaskState from '../../task-states/models/IssuedTaskState';
import TryItArea from '@/tasks/components/TryItArea';
import User from '@/users/models/User';
import CourseLikeAssignmentInfo from '@/assignments/components/CourseLikeAssignmentInfo';
import RawStateDump from '@/tasks/components/RawStateDump';
import AssignmentProgressBar from '@/assignments/components/AssignmentProgressBar';
import {Paginator} from '@/common/utils/pagination';
import {getTaskResponses} from '@/tasks/api/task-responses';
import TaskNavigationCard from '@/assignments/components/TaskNavigationCard';
import CourseRole from '@/users/models/CourseRole';
import UserSelector from '@/users/components/UserSelector';
import {useRoute} from '@/router/composables';
import AssignmentGrade from '@/grades/models/AssignmentGrade';
import {ref} from '@vue/composition-api';
import {provideRandomNumberBackground} from '@/tasks/components/composables/useRandomNumberBackgroundStyle';

export default {
  name: 'Quiz',
  components: {
    UserSelector,
    TaskNavigationCard,
    AssignmentProgressBar,
    RawStateDump,
    CourseLikeAssignmentInfo,
    TryItArea,
  },
  props: {
    course: {
      type: Course,
      required: true,
    },
    assignmentId: {
      type: [String, Number],
      required: true,
    },
    latestExtension: {
      type: AssignmentExtension,
      default: null,
    },
  },
  setup() {
    return provideRandomNumberBackground();
  },
  data: () => ({
    preview_assignment: false,
    alertVideo: false,
    alertBook: false,
    submitQuiz: false,
    toolTip_navi: [
      {
        title: 'Not completed',
        color: 'grey lighten-2',
      },
      {
        title: 'Responded',
        color: 'black',
      },
      {
        title: 'Incorrect',
        color: 'red',
      },
      {
        title: 'Partially correct',
        color: 'blue',
      },
      {
        title: 'Correct',
        color: 'green',
      },
    ],
    selectedResponseIndices: {},
    isSubmitting: false,
    tryIt: false,
    isGradeLocked: false,
    tryItData: {
      responses: [],
      grades: [],
    },
  }),

  computed: {
    user() {
      return this.isMarking && this.routeUserId
        ? User.query().find(this.routeUserId)
        : this.$auth.user;
    },
    routeUserId() {
      const routeUserId = this.$route.params.userId;
      return routeUserId ? parseInt(routeUserId) : null;
    },
    isMarking() {
      return this.$route.name === MARKING_TASKS;
    },
    courseLikeAssignments() {
      return this.assignment.courseLikeAssignments;
    },
    firstCourseLikeAssignment() {
      // FIXME: this will need to be updated when CourseLikeAssignment logic is settled
      return this.courseLikeAssignments[0] ? this.courseLikeAssignments[0] : null;
    },
    allowRegrade() {
      const urlPattern = /^\/courses\/(\d+)\/assignments\/(\d+)\/marking\/(\d+)\/tasks\/(\d+)$/;
      const location = useRoute();

      return urlPattern.test(location.fullPath);
    },
    assignment() {
      return Assignment.fullQuery({
        taskStates: true,
        taskContent: true,
        course: true,
        courseLikeAssignments: true,
        taskAssignments: true,
        responses: true,
        feedback: true,
        grades: true,
      }).find(this.assignmentId);
    },
    isReady() {
      return this.isAssignmentReady && this.isTaskAreaReady;
    },
    isAssignmentReady() {
      return this.isAssignmentLoaded && this.assignment;
    },
    isTaskAreaReady() {
      return this.taskAssignments;
    },
    isAssignmentLoaded() {
      return this.$loadingFlags.isLoaded(LoadingFlag.AssignmentWrapperAssignment);
    },
    hasNoTasks() {
      return this.filteredTasks.length === 0;
    },
    taskIndex() {
      const routeTaskNumber = this.$route.params.taskNumber;
      if (routeTaskNumber) {
        const taskIndex = this.taskIdToNumberMap[routeTaskNumber];
        if (taskIndex) {
          return parseInt(taskIndex);
        }
      }
      return 1;
    },
    taskIdToNumberMap() {
      let taskIdToNumberMap = {};
      this.filteredTaskAssignments.forEach((taskAssignment, i) => {
        taskIdToNumberMap[taskAssignment.taskId] = i + 1;
      });
      return taskIdToNumberMap;
    },
    taskArrayIndex() {
      return this.taskIndex - 1;
    },
    taskAssignments() {
      if (!this.assignment) {
        return [];
      }
      return this.assignment.taskAssignments;
    },
    filteredTaskAssignments() {
      return this.taskAssignments.filter(
        (taskAssignment) => taskAssignment.task.visibleInAssignment
      );
    },
    tasks() {
      return this.taskAssignments.map((taskAssignment) => taskAssignment.task);
    },
    filteredTasks() {
      return this.filteredTaskAssignments.map((taskAssignment) => taskAssignment.task);
    },
    totalAssignmentMarks() {
      return this.taskAssignments.reduce(
        (acc, taskAssignment) => acc + taskAssignment.pointValue,
        0
      );
    },
    grades() {
      return this.taskAssignments.map((taskAssignment) =>
        taskAssignment.effectiveGradeForUser(this.user.id)
      );
    },
    filteredGrades() {
      return this.filteredTaskAssignments.map((taskAssignment) =>
        taskAssignment.effectiveGradeForUser(this.user.id)
      );
    },
    filteredResponses() {
      return this.filteredTasks.map((task) => {
        return task.getResponsesForUserAssignment(this.user.id, this.assignmentId);
      });
    },

    /*
     * Selected data for each task in this assignment
     */
    selectedResponses() {
      return this.filteredResponses.map((responses, i) => {
        // Responses are sorted by oldest to most recent
        const response = responses[this.getSelectedResponseIndexFor(i)];
        return response ? response : null;
      });
    },
    selectedGrades() {
      return this.selectedResponses.map((selectedResponse) => {
        return selectedResponse ? selectedResponse.grade : null;
      });
    },
    selectedFeedbacks() {
      return this.selectedResponses.map((selectedResponse) => {
        return selectedResponse ? selectedResponse.getFeedbackByPart() : null;
      });
    },
    selectedTaskStates() {
      return this.selectedTaskStateIds.map((selectedTaskStateId) => {
        if (selectedTaskStateId !== null) {
          return TaskState.fullQuery().whereId(selectedTaskStateId).first();
        }
        return null;
      });
    },
    selectedTaskStateIds() {
      return this.selectedResponses.map((selectedResponse, i) => {
        const task = this.filteredTasks[i];
        if (selectedResponse && selectedResponse.taskState) {
          return selectedResponse.taskState.id;
        } else if (task.taskStates.length) {
          const issuedState = IssuedTaskState.queryByUserAssignmentTask(
            this.user.id,
            this.assignment.id,
            task.id
          ).first();
          return issuedState ? issuedState.taskStateId : null;
        }
        return null;
      });
    },

    assignmentGrade() {
      return (
        AssignmentGrade.queryByUserAssignment(this.user.id, this.assignmentId)
          .with('gradeAdjustments')
          .first() || null
      );
    },
    /*
     * Current data depending on currently active task
     */
    currentTask() {
      return this.filteredTasks[this.taskArrayIndex];
    },
    currentTaskMarks() {
      return this.filteredTaskAssignments[this.taskArrayIndex].pointValue;
    },
    currentTaskAssignment() {
      return this.filteredTaskAssignments[this.taskArrayIndex];
    },
    currentResponseIndex() {
      return this.getSelectedResponseIndexFor(this.taskArrayIndex);
    },
    currentResponse() {
      return this.selectedResponses[this.taskArrayIndex];
    },
    currentResponses() {
      return this.filteredResponses[this.taskArrayIndex];
    },
    currentTaskState() {
      return this.selectedTaskStates[this.taskArrayIndex];
    },
    currentFeedback() {
      return this.selectedFeedbacks[this.taskArrayIndex];
    },
    currentGrade() {
      return this.selectedGrades[this.taskArrayIndex];
    },
    currentEffectiveGrade() {
      return this.filteredGrades[this.taskArrayIndex];
    },
    numTasks() {
      return this.filteredTasks.length;
    },
    numCompletedTasks() {
      return this.filteredGrades.reduce((completed, grade, index) => {
        const responses = this.filteredResponses[index];
        if (grade || (responses && responses.some((r) => r.submittedAt))) {
          return completed + 1;
        }
        return completed;
      }, 0);
    },
    hasGrades() {
      return this.filteredGrades.reduce((acc, item) => acc || item !== null, false);
    },
    isStateLoading() {
      return this.$loadingFlags.isLoading(LoadingFlag.AssignmentTaskState);
    },
    isResponsesLoading() {
      return this.$loadingFlags.isLoading(LoadingFlag.AssignmentTaskResponses);
    },
  },

  created() {
    const routeTaskNumber = this.$route.params.taskNumber;
    if (routeTaskNumber === undefined || this.taskIdToNumberMap[routeTaskNumber] === undefined) {
      const taskByIndex = this.filteredTaskAssignments[0];
      this.$router.replace({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          taskNumber: taskByIndex.taskId.toString(),
        },
      });
    }

    this.fetchAssignmentData().then(() => this.fetchStudentData());

    if (this.isMarking && !this.$gate.can('viewGradesFor', 'Assignment', this.assignment)) {
      this.$router.replace({
        name: ASSIGNMENT_OVERVIEW,
        params: this.$route.params,
      });
    }
  },

  methods: {
    fetchAssignmentData() {
      const assignmentDataPromise = this.$loadingFlags.loadingHandler(
        LoadingFlag.AssignmentTaskGrades,
        Grade.api.loadGradesForAssignment(this.user.id, this.assignmentId).catch((err) => {
          // Students might not be able to see their grades yet
          if (err.response && err.response.status === 403) {
            return;
          }
          return this.$errorReporting.errorDialogHandler(err);
        })
      );

      // Load responses
      this.fetchTaskResponses();
      this.fetchAssignmentGrade();

      return assignmentDataPromise;
    },
    fetchTaskResponses() {
      const responseFunction = ({page, pageSize = 10}) => {
        return getTaskResponses({
          user: this.user.id,
          assignment: this.assignmentId,
          includeAttachments: true,
          page,
          pageSize,
        });
      };
      const paginator = new Paginator(responseFunction);

      this.$loadingFlags
        .loadingHandler(LoadingFlag.AssignmentTaskResponses, () =>
          paginator.stream().then((responses) => {
            const data = responses
              .map((r) => r.data.data)
              .reduce((acc, items) => [...acc, ...items], []);
            return TaskResponse.insertOrUpdate({data});
          })
        )
        .catch(this.$errorReporting.errorDialogHandler);
    },
    fetchStudentData() {
      if (this.isMarking) {
        this.$loadingFlags
          .loadingHandler(
            LoadingFlag.MarkingViewLoadStudents,
            CourseRole.api.fetch({
              courseLike: this.assignment?.courseId,
              checkSections: true,
              includeUser: true,
              includeRole: true,
            })
          )
          .catch(this.$errorReporting.errorDialogHandler);
      }
    },
    fetchAssignmentGrade() {
      this.$loadingFlags.loadingHandler(
        LoadingFlag.AssignmentAssignmentGrade,
        AssignmentGrade.api
          .getGradeForUserOnAssignment(this.user.id, this.assignmentId)
          .catch((err) => {
            if (err && err.response && err.response.status === 403) {
              this.isGradeLocked = true;
              return;
            }
            return this.$errorReporting.errorDialogHandler(err);
          })
      );
    },
    delayedFetchAssignmentGrades() {
      // FIXME: This is hacky, but there's no way to guarantee the grade has been updated since it's asynchronous.
      //        Not sure making it synchronous is a good idea given that it could become heavy depending on policies.
      setTimeout(() => this.fetchAssignmentGrade(), 1000);
    },
    restart() {
      let taskByIndex = this.filteredTaskAssignments[0];
      this.$router.push(taskByIndex.taskId.toString());
    },
    next() {
      const nextIndex = this.taskArrayIndex + 1;
      if (nextIndex < this.numTasks) {
        let taskByIndex = this.filteredTaskAssignments[nextIndex];
        this.$router.push(taskByIndex.taskId.toString());
      }
    },
    gotoTask(index) {
      if (index > 0 && index <= this.numTasks && index !== this.taskIndex) {
        let taskByIndex = this.filteredTaskAssignments[index - 1];
        this.$router.push(taskByIndex.taskId.toString());
      }
    },
    previous() {
      const previous = this.taskArrayIndex - 1;
      if (previous >= 0) {
        let taskByIndex = this.filteredTaskAssignments[previous];
        this.$router.push(taskByIndex.taskId.toString());
      }
    },
    getSelectedResponseIndexFor(i) {
      if (this.selectedResponseIndices[i] === undefined) {
        const responsesLength = this.filteredResponses[i] ? this.filteredResponses[i].length : 0;
        Vue.set(this.selectedResponseIndices, i, responsesLength ? responsesLength - 1 : 0);
      }
      return this.selectedResponseIndices[i];
    },
    changeSelectedResponse(newIndex, taskIndex = null) {
      if (taskIndex === null) {
        taskIndex = this.taskArrayIndex;
      }
      if (newIndex >= 0 && newIndex <= this.filteredResponses[taskIndex].length) {
        Vue.set(this.selectedResponseIndices, taskIndex, newIndex);
      }
    },
    selectNewResponse(taskIndex = null) {
      if (taskIndex === null) {
        taskIndex = this.taskArrayIndex;
      }
      this.changeSelectedResponse(this.currentResponses.length, taskIndex);
    },
    selectLatestResponse(taskIndex = null) {
      if (taskIndex === null) {
        taskIndex = this.taskArrayIndex;
      }
      this.changeSelectedResponse(
        this.currentResponses.length ? this.currentResponses.length - 1 : 0,
        taskIndex
      );
    },

    // Response submission
    triggerSubmitResponse() {
      this.$refs.taskCard.triggerSubmitResponse();
    },
    reloadTask() {
      IssuedTaskState.api
        .getForTask(this.task.id, {
          assignment: this.assignment.id,
          reissue: true,
        })
        .then(() => {
          this.selectNewResponse();
        })
        .catch(this.$errorReporting.errorDialogHandler);
    },
    attemptAttachments(responses) {
      return (
        responses &&
        responses.filter((response) => response.attachments && response.attachments.length > 0)
          .length > 0
      );
    },

    selectResponseForNavCard(responses, grade = null) {
      if (responses && grade) {
        return responses.find((r) => {
          if (r.grade) {
            return r.grade.isEffective;
          }
        });
      } else if (responses && !grade) {
        return responses.slice(-1)[0];
      }
    },

    resetForNewQuestion: function () {
      this.alertBook = false;
      this.alertVideo = false;
      this.responsesAlter = false;
    },
    changeSelectedUser(user) {
      this.$router
        .push({
          name: MARKING_TASKS,
          params: {
            ...this.$route.params,
            userId: user.id,
          },
        })
        .then(() => {
          this.fetchAssignmentData();
          this.$refs.tryItArea.taskCardUpdate();
        });
    },
  },
};
</script>

<style lang="scss">
.submitButton {
  background-color: #d83d0e !important;
  border: 0px !important;
}

.theme--light.v-label {
  color: black !important;
}

.is-selected {
  background-color: #b9c9d8 !important;
  color: white !important;
}

.is-active-reload {
  color: #ffac6e !important;
}

.text-btn::before {
  color: transparent !important;
}

.text-btn:hover {
  color: #d83d0e !important;
}

.text-btn {
  margin-right: -18px;
}

.no-background-hover::before {
  background-color: transparent !important;
}

.v-card__text {
  line-height: 28px;
}

.progressContainer {
  width: 60%;
  margin: 0 auto;
}

.progress {
  margin: 0;
  border-radius: 5rem;
  overflow: hidden;
  border: none;
  background-color: red !important;
}

.task-navigation-list {
  width: 100%;
  display: grid;

  /* Responsive auto-filling grid */
  /* https://css-tricks.com/an-auto-filling-css-grid-with-max-columns/ */

  --grid-layout-gap: 0.75rem;
  --grid-column-count: 8;
  --grid-item--min-width: 3rem;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  grid-gap: var(--grid-layout-gap);
}

.task-marks {
  color: #525252;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.task-marks strong {
  font-weight: 500;
  color: #262626;
}

.user-navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: stretch;
  margin-bottom: 1.5rem;
}

.opacity-70 {
  opacity: 0.7;
}

@media only screen and (min-width: 768px) {
  .quiz_card_height {
    height: 400px;
  }

  .quiz_button_width {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .quiz_card_height {
    height: 100%;
  }

  .quiz_button_width {
    width: 100%;
  }
}
</style>
