<template>
  <div>
    <v-simple-table dense>
      <thead>
        <tr>
          <th>Variable Name</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="[symbol, valueType, value] in valueEntries" :key="symbol">
          <td>
            {{ symbol }}
          </td>
          <td>
            <component :is="VALUE_TYPE_TO_COMPONENT[valueType]" :value="value" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import TaskState from '@/task-states/models/TaskState';
import ChemicalElementValue from '@/tasks/components/ChemicalElementValue';
import ChemicalIsotopeValue from '@/tasks/components/ChemicalIsotopeValue';
import NumberValue from '@/tasks/components/NumberValue';
import IntegerValue from '@/tasks/components/IntegerValue';

const VALUE_TYPE_TO_COMPONENT = {
  NumberValue: 'NumberValue',
  ChemicalElementValue: 'ChemicalElementValue',
  ChemicalIsotopeValue: 'ChemicalIsotopeValue',
  IntegerValue: 'IntegerValue',
};

export default {
  name: 'RawStateDump',
  components: {
    NumberValue,
    ChemicalIsotopeValue,
    ChemicalElementValue,
    IntegerValue,
  },
  props: {
    taskState: {
      type: TaskState,
      required: true,
    },
  },
  data() {
    return {
      VALUE_TYPE_TO_COMPONENT,
    };
  },
  computed: {
    valuesBySymbol() {
      return this.taskState.symbols.reduce((acc, symbol) => {
        acc[symbol] = this.taskState.getValueBySymbol(symbol);
        return acc;
      }, {});
    },
    valueEntries() {
      return Object.entries(this.valuesBySymbol).map(([symbol, variableValue]) => {
        return [symbol, variableValue.valueType, variableValue.content];
      });
    },
  },
};
</script>
