






















import {computed, defineComponent, PropType} from '@vue/composition-api';
import {inject} from '@/container';
import {UseResponseDots} from '@/tasks/composables/UseResponseDots';
import TaskResponse from '@/tasks/models/TaskResponse';
import Grade from '@/grades/models/Grade';

export default defineComponent({
  name: 'TaskNavigationCard',
  props: {
    index: {
      type: Number as PropType<number>,
      required: true,
    },
    active: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    response: {
      type: Object as PropType<TaskResponse>,
      required: false,
      default: null,
    },
    grade: {
      type: Object as PropType<Grade>,
      required: false,
      default: null,
    },
    hasAttachments: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, {emit}) {
    const {getResponseInformation} = inject(UseResponseDots, emit);
    const responseData = computed(() => getResponseInformation(props.response, props.grade, false));

    return {responseData};
  },
});
